<template>
    <vx-card :title="'View Requested Simple Stock Transfer Order ( ' + this.docStatus + ' )'">
        <div class="vx-row mb-6">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Inter Warehouse STO Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>STO Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="selectedType" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedSrcWarehouse ? this.selectedSrcWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedDestWarehouse ? this.selectedDestWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Imposition</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.selectedImposition ? this.selectedImposition.label : ''" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Req. Delivery Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.reqDeliveryDate" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Est. Time Arrived</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.eta" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Estimate Shipment Cost</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="text" :value="shipmentCost" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Ratio (%)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="costRatio" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" width="100%" :readonly="true"/>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Reason</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="reason" width="100%" :readonly="this.status !== 0"/>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Relocation Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th style="width: 35%;">SKU</th>
                        <th style="width: 25%;">Batch / Exp. Date</th>
                        <th style="width: 10%;">Moved Qty</th>
                        <th style="width: 10%;">HU</th>
                        <th style="width: 10%;">Amount UOM</th>
                        <th style="width: 10%;">Total Qty</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" v-model="skuLine.sku_label" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" v-model="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="number" v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" v-model="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="number" v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input class="w-full" type="text" v-model="skuLine.formated_total_qty" :readonly="true" style="width: auto;"></vs-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/2">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button v-if="this.status === 0" class="mr-3 mb-2" color="success"
                        @click="handleAccept">Accept</vs-button>
                    <vs-button v-if="this.status === 0" class="mr-3 mb-2" color="danger"
                        @click="handleReject">Reject</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Swal from 'sweetalert2'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        VueEasyLightbox,
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            selectedType: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            selectedImposition: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            shipmentCost: 0,
            costRatio: 0,
            status: null,
            docStatus: "Loading..",
            tabOrder: 0,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    sku_label: null,
                    unit: null,
                    qty: 0,
                    batch: null,
                    qty_uom: 0,
                    total_qty: 0,
                    formated_total_qty: null,
                }
            ],
            action: null,
            reason: "",

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        handleUpdateFormat(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/approval/simple-sto/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.code
                    this.selectedType = resp.data.sto_type
                    this.selectedSrcWarehouse = resp.data.src_warehouse
                    this.selectedDestWarehouse = resp.data.dest_warehouse
                    this.selectedImposition = resp.data.imposition
                    this.reqDeliveryDate = resp.data.delivery_date
                    this.eta = resp.data.eta
                    this.notes = resp.data.notes
                    this.shipmentCost = this.handleUpdateFormat(resp.data.shipment_cost)
                    this.costRatio = resp.data.cost_ratio
                    this.status = resp.data.approval_status
                    this.reason = resp.data.approver_reason
                    this.skuLines = resp.data.sku_lines
                    this.skuLines.forEach(val => {
                        val.formated_total_qty = this.handleUpdateFormat(val.total_qty)
                    })
                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }

                    if (this.status == 0) {
                        this.docStatus = "Waiting Approval";
                    } else if (this.status == 1) {
                        this.docStatus = "Approved";
                    } else if (this.status == 2) {
                        this.docStatus = "Rejected";
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleUjiCoba() {
            this.$http.post("/api/wms/v1/approval/simple-sto/meja-bundar")
        },
        handleAccept() {
            this.action = "approve";
            let formData = new FormData();
            formData.append("reason", this.reason);
            Swal.fire({
                title: 'Approve this document',
                text: "Are you sure want to approve this plan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Approve'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/approval/simple-sto/status/" + this.id + "/" + this.action, formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Done!',
                                text: 'Plan Document has been approved.',
                                icon: 'success',
                            })
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: 'Something went wrong',
                                text: resp.message,
                                icon: 'info'
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Something went wrong',
                            text: error,
                            icon: 'info'
                        })
                    });
                }
            });
        },
        handleInquiry() {
            if (this.reason == "") {
                Swal.fire({
                    title: 'Empty reason column',
                    text: "please fill the reason column",
                    icon: 'info'
                })
            }

            this.action = "inquiry";
            let formData = new FormData();
            formData.append("notes", this.notes);
            Swal.fire({
                title: 'Inquiry this document',
                text: "Are you sure want to inquiry this plan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Inquiry'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/approval/simple-sto/status/" + this.id + "/" + this.action, formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire(
                                'Done!',
                                'Plan Document status is INQUIRY.',
                                'success'
                            )
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            Swal.fire(
                                'Something gone wrong',
                                'ERROR : UNKNOWN',
                                'info'
                            )
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleReject() {
            if (this.reason == "") {
                Swal.fire({
                    title: 'Empty reason column',
                    text: "please fill the reason column",
                    icon: 'info'
                })
            }

            this.action = "reject";
            let formData = new FormData();
            formData.append("reason", this.reason);
            Swal.fire({
                title: 'Reject this document',
                text: "Are you sure want to reject this plan?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Reject'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/approval/simple-sto/status/" + this.id + "/" + this.action, formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire(
                                'Done!',
                                'Plan Document has been rejected.',
                                'success'
                            )
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            Swal.fire(
                                'Something gone wrong',
                                'ERROR : UNKNOWN',
                                'info'
                            )
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "approval.simple-sto",
                params: { status: this.status }
            });
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    },
    watch: {},
    computed: {
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>